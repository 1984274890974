export const data = [
  {
    position: "Accounts Manager",
    MailID: "sujatha@consol.express",
    contact: "+91 44-42115913",
    Name: "Ms.M.SUJATHA",
  },
  {
    position: "Sales Manager",
    MailID: "info@consol.express",
    contact: "+91 75500 14100",
    Name: "Mr.H.VINOTH",
  },
  {
    position: "Assistant Manager Sales",
    MailID: "kumar.maa@consol.express",
    contact: "+91 98403 10345",
    Name: "Mr.P.KUMAR",
  },
  {
    position: "Sales & Customer Support",
    MailID: "sales.maa@consol.express",
    contact: "+91 81220 24034",
    Name: "Ms.K.RITHIKA",
  },
  {
    position: "Customer Service",
    MailID: "doc.maa@consol.express",
    contact: "+91 98403 10345",
    Name: "Mr.M.PRAVEEN",
  },
  {
    position: "Accounts Executive",
    MailID: "acct.maa@consol.express",
    contact: "+91 73059 59345",
    Name: "Mr.K.SUBABATHI",
  },
  {
    position: "Accounts Assistant",
    MailID: "acct1.maa@consol.express",
    contact: "+91 73059 59345",
    Name: "Ms. B. JAYALAKSHMI",
  },

  {
    position: "Operation Executive",
    MailID: "no mail",
    contact: "+91 96771 83743",
    Name: "Mr.J.DINESH",
  },
];

export const data2 = [
  {
    position: "General Manager",
    MailID: "arun@consol.express",
    contact: "+91 91592 12487",
    Name: "Mr.T.ARUN KUMAR",
  },
  {
    position: "Business Development Manager",
    MailID: "sales.cbe@consol.express",
    contact: "+91 96295 33369",
    Name: "Mr.J.SANTHOSH KUMAR",
  },
  {
    position: "Assistant Manager",
    MailID: "mktg.cbe@consol.express",
    contact: "+91 80156 58605",
    Name: "Mr.G.VISHNU",
  },
  {
    position: "Executive Documentation",
    MailID: "doc.cbe@consol.express",
    contact: "+91 63818 83689",
    Name: "Mr.M.ABDUL RAHMAN",
  },
];
