import React from "react";
import "./Footer.css";
import FootImg from "../../assets/FooterImg.jpeg";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer>
      <div className="Waves">
        <div className="Wave" id="wave1"></div>
        <div className="Wave" id="wave2"></div>
        <div className="Wave" id="wave3"></div>
        <div className="Wave" id="wave4"></div>
      </div>
      <div className="row">
        <div className="col">
          <img src={FootImg} alt="" />
        </div>
        <div className="col">
          <h3>
            Contactus
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <p>
            Chennai :Old # 125, New # G - 67, 1st Avenue, Anna Nagar East,2nd
            Floor Chennai 600 102 -India.
          </p>
          <p>
            Coimbatore :No. 328, 2nd floor, Sri Kumaran Towers,Trichy Road
            Singanallur, 641 005 -India.
          </p>
          <p className="email-id">info@consol.express</p>
          <h4>+91 44-42115113 </h4>
          <h4>0422-4959293 </h4>
        </div>
        <div className="col">
          <h3>
            Links
            <div className="underline">
              <span></span>
            </div>
          </h3>
          <ul>
            <li>
              <Link
                to="Home-img"
                smooth={true}
                offset={-100}
                duration={500}
                href="#Home"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="products-container"
                smooth={true}
                offset={-200}
                duration={500}
                href="#Services"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="about-us-container"
                smooth={true}
                offset={-140}
                duration={500}
                href="#About"
              >
                About us
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <p className="text">
        Copyright © 2024, Consolexpress. All Rights Reserved. Developed by
        <a
          href="http://invosys.co.in/"
          style={{ color: "#a4317d", position: "relative", left: "4px" }}
        >
          Invoking Sytems Pvt.Ltd
        </a>
      </p>
    </footer>
  );
};

export default Footer;
